<template>
  <div :ref="'image' + id" class="">
    <img
      v-if="id"
      :src="imageUrl"
      :alt="$t('drawer:image_loader.image.alt')"
      :style="style"
    />
    <img
      v-else
      :src="require('@/assets/images/missing_default.png')"
      :alt="$t('drawer:image_loader.missing_image.alt')"
      :style="style"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "ImageLoader",
  props: {
    id: {
      type: String,
      default: null,
    },
    maxWidth: {
      type: String,
      default: "64px",
    },
    minWidth: {
      type: String,
      default: null,
    },
    borderRadius: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      loading: null,
    };
  },
  computed: {
    imageUrl() {
      let blob = this.fileGetter(this.id);
      if (blob) {
        return window.URL.createObjectURL(blob);
      } else {
        return null;
      }
    },
    style() {
      let style = `width: ${this.maxWidth};`;

      if (this.borderRadius) {
        style += `border-radius: ${this.borderRadius};`;
      }

      if (this.minWidth) {
        style += `min-width: ${this.minWidth};`;
      }

      return style;
    },
    ...mapGetters({
      fileGetter: "drawer/getFile",
    }),
  },
  async mounted() {
    if (this.id && this.id !== "null") {
      this.loading = this.$buefy.loading.open({
        container: this.$refs["image" + this.id].$el,
      });
      try {
        await this.fetchFile(this.id);
      } finally {
        this.loading.close();
      }
    }
  },
  methods: {
    ...mapActions({
      fetchFile: "drawer/download",
    }),
  },
};
</script>

<style lang="scss"></style>
